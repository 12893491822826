<template>
  <!--begin::Product Listing-->
  <div class="product-template">
    <PageHeaderCount
      moduleType="product"
      :dataLoading="dataLoading"
      :statusList="statusList"
      :extraList="topExtraList"
      allkey="all_products"
      countkey="product_status_count"
    ></PageHeaderCount>

    <v-col
      v-if="alphabets.length && false"
      md="12"
      class="alphabets-filter d-flex justify-center"
    >
      <div class="alphabets-scrollable">
        <v-btn
          :disabled="searching"
          depressed
          v-on:click="getLineItem(null, 'alphabet')"
          :class="{
            'cyan--text': null == alphabet,
            'cyan white--text': null != alphabet,
          }"
          class="custom-bold-button"
          small
          >All</v-btn
        >

        <v-btn
          :disabled="searching"
          depressed
          v-for="(row, index) in alphabets"
          v-on:click="getLineItem(row, 'alphabet')"
          :class="{
            'cyan--text': row == alphabet,
            'cyan white--text': row != alphabet,
          }"
          class="custom-bold-button"
          small
          :key="index"
          >{{ row }}
          <!-- <v-badge
            bordered
            color="error"
            class="custom-margin"
            overlap
            dot
            offset-x="10"
            offset-y="10"
          >
          </v-badge> -->
        </v-btn>
      </div>
    </v-col>
    <ListingTemplate
      :customClass="'product-listing'"
      v-if="getPermission('product:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="green lighten-1 white--text"
                  v-on:click="exportCompare('specs')"
                  depressed
                >
                  <v-icon dark left>mdi-compare</v-icon>Specs Compare
                </v-btn>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="green lighten-1 white--text"
                  v-on:click="exportCompare('price')"
                  depressed
                >
                  <v-icon dark left>mdi-compare</v-icon>Price Compare
                </v-btn>

                <template v-if="getPermission('product:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                        depressed
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="productBulkAction(item.status, item)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                  depressed
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0">
                <v-layout class="w-100">
                  <v-flex class="">
                    <h1 class="form-title d-flex margin-auto">
                      <!-- <pre>{{statusList}}</pre> -->
                      <v-select
                        :disabled="dataLoading"
                        :items="statusList"
                        v-model="status"
                        hide-details
                        item-color="cyan"
                        class="pt-0 mt-0 mr-1 listing-status main-listing-status-filter"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-text="item.description"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              :color="item.color"
                              :text-color="item.textcolor"
                            >
                              <template v-if="item.value == 'all'">{{
                                item.all_products
                              }}</template>
                              <template v-else>{{
                                item.product_status_count
                              }}</template>
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-select>
                    </h1>
                  </v-flex>
                  <v-flex class="mxw-200">
                    <h1 class="form-title d-flex margin-auto">
                      <v-select
                        :disabled="dataLoading"
                        :items="categoryList"
                        v-model="category"
                        v-on:change="getRows()"
                        hide-details
                        item-color="cyan"
                        class="pt-0 mt-0 listing-status main-listing-status-filter"
                        item-text="text"
                        item-value="id"
                      >
                        <template v-slot:item="{ item }">
                          <v-list-item-content
                            v-on:click="setCategoryName(item.text)"
                          >
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              v-if="item.text != 'All Conditions'"
                              color="cyan"
                              text-color="white"
                            >
                              <!-- <template v-if="!item.id">{{
                                item.all_products
                              }}</template> -->
                              <template>{{
                                item.quotation_status_count
                              }}</template>
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-select>
                    </h1>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('product:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('product.create')"
                    depressed
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <template v-if="getPermission('product:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 green"
                    v-on:click="importDialogImg = true"
                    color="cyan white--text"
                    depressed
                  >
                    <v-icon dark left>mdi-database-import</v-icon> Import Images
                  </v-btn>

                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="importDialog = true"
                    color="cyan white--text"
                    depressed
                  >
                    <v-icon dark left>mdi-database-import</v-icon> Import
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  v-on:click="exportQuotation"
                  color="cyan white--text"
                  depressed
                >
                  <v-icon dark left>mdi-database-export</v-icon> Export
                </v-btn>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                  depressed
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!--   <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'customer',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <!--  <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      depressed
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    v-on:change="updateVisible()"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          v-on:change="updateVisible()"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips v-if="false" module="product"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Product"
          :basicSearchFields="['name', 'barcode']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': Number(cols.checkbox) }"
                      :style="{ 'max-width': cols.width }"
                      :cols="Number(cols.checkbox)"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    class="product-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin ml-1"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <v-avatar size="70">
                            <v-img
                              class="customer-image"
                              width="70"
                              height="70"
                              contain
                              :lazy-src="$defaultImage"
                              :src="$assetAPIURL(data.product_image)"
                            >
                            </v-img>
                          </v-avatar>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'product_name'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Seller: </b>
                            <template v-if="data?.suppliers[0]?.supplier_name">
                              <v-img
                                :lazy-src="$defaultProfileImage"
                                :src="
                                  data?.suppliers[0]?.supplier_image
                                    ? data?.suppliers[0]?.supplier_image
                                    : $defaultProfileImage
                                "
                                aspect-ratio="1"
                                class="margin-auto"
                                transition="fade-transition"
                                style="
                                  width: 20px;
                                  height: 20px;
                                  display: inline-block;
                                  margin-bottom: -5px !important;
                                  border-radius: 50%;
                                "
                              ></v-img>
                              {{ data?.suppliers[0]?.supplier_name }}
                            </template>
                            <em v-else>-</em>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis" v-if="false">
                            <b>Seller Code: </b>
                            <template v-if="data.part_number">{{
                              data.part_number
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Seller Code</em
                              ></template
                            >
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'product_info'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Brand: </b>
                            <template v-if="data?.brand">{{
                              data?.brand
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Brand</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Model code 1: </b>
                            <template v-if="data?.model">{{
                              data?.model
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Model code 1</em
                              ></template
                            >
                          </p>
                          <!--  <p class="m-0 custom-nowrap-ellipsis">
                            <b>Serial Number: </b>
                            <template v-if="data.serial_number">{{
                              data.serial_number
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Serial Number</em
                              ></template
                            >
                          </p> -->
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Condition: </b>
                            <template v-if="data.category">{{
                              data.category
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Condition</em
                              ></template
                            >
                          </p>
                          <template v-if="false">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference #: </b>
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Reference #</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>SKU: </b>
                              <template v-if="data.sku">{{
                                data.sku
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No SKU</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>UPC: </b>
                              <template v-if="data.upc">{{
                                data.upc
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No UPC</em
                                ></template
                              >
                            </p>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'dimension'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Length: </b>
                            {{ data.length }} mm
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Width: </b>
                            {{ data.width }} mm
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Height: </b>
                            {{ data.height }} mm
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Weight: </b>
                            {{ data.weight }} kg
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'commission_rate'">
                          {{ data.commission_rate }}
                        </template>
                        <template v-else-if="cols.field == 'price'">
                          <!-- <p class="m-0 custom-nowrap-ellipsis">
                            <b>Project Price: </b>
                            <template v-if="data.project_price">{{
                              $accountingJS.formatMoney(data.project_price)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Project Price</em
                              ></template
                            >
                          </p> -->
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Selling Price/Unit Price: </b>
                            <template v-if="data.sale_price">{{
                              $accountingJS.formatMoney(data.sale_price)
                            }}</template>
                            <template v-else><span> $ 0.00</span></template>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Discounted Price: </b>
                            <template v-if="data.discount_price">{{
                              $accountingJS.formatMoney(data.discount_price)
                            }}</template>
                            <template v-else><span>$ 0.00</span></template>
                          </p>
                          <!-- <p class="m-0 custom-nowrap-ellipsis">
                            <b>Threshold: </b>
                            <template v-if="data.threshold_price">{{
                              $accountingJS.formatMoney(data.threshold_price)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Threshold
                              </em></template
                            >
                          </p> -->
                        </template>

                        <template v-else-if="cols.field == 'supplier'">
                          <div class="d-flex align-items-center">
                            <template
                              v-if="getFilteredSupplers(data.suppliers).length"
                            >
                              <template
                                v-for="(row, index) in getFilteredSupplers(
                                  data.suppliers
                                )"
                              >
                                <v-tooltip
                                  top
                                  content-class="custom-top-tooltip"
                                  :key="'tooltip' + index"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-avatar
                                      v-bind="attrs"
                                      v-on="on"
                                      v-if="index < 2"
                                      color="blue lighten-4"
                                      class="ml-2"
                                      size="40"
                                      :style="`margin-left:${
                                        index > 0 ? '-12px' : ''
                                      };border:1px solid #0000002e !important`"
                                    >
                                      <template
                                        v-if="row && row.supplier_image"
                                      >
                                        <v-img
                                          :src="row.supplier_image"
                                        ></v-img>
                                      </template>
                                      <span
                                        v-else
                                        style="font-size: 14px"
                                        class=""
                                      >
                                        {{
                                          getTwoCharacter(
                                            row && row.supplier_name
                                          )
                                        }}
                                      </span>
                                    </v-avatar>
                                  </template>
                                  <span style="font-size: 11px">{{
                                    row.supplier_name
                                  }}</span>
                                </v-tooltip>
                                <v-chip
                                  v-if="index == 2"
                                  :key="'span' + index"
                                  x-small
                                  label
                                  color="blue darken-4 white--text"
                                  class="px-1"
                                >
                                  +{{ data.suppliers.length - 2 }} more
                                </v-chip>
                                <!-- @click.stop.prevent="routeToGo('suppliers', td.uuid)" -->
                                <!-- ({{ td.suppliers.length - 2 }} more)</span> -->
                              </template>
                            </template>
                            <template v-else>
                              <span>
                                <em class="text-muted">No supplier</em>
                              </span>
                            </template>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'quantity'">
                          <p v-if="false" class="m-0 custom-nowrap-ellipsis">
                            <v-tooltip left content-class="custom-left-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="tooltip-border-dashed"
                                  ><b>Initial: </b
                                  >{{
                                    $accountingJS.formatNumber(
                                      data.initial_stock
                                    )
                                  }}</span
                                >
                              </template>
                              <span
                                >The stock available for sale at<br />the
                                beginning of the accounting period.</span
                              >
                            </v-tooltip>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <v-tooltip left content-class="custom-left-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="tooltip-border-dashed"
                                  ><b>On Hand: </b
                                  >{{
                                    $accountingJS.formatNumber(
                                      data.stock_on_hand
                                    )
                                  }}</span
                                >
                              </template>
                              <span
                                >Current stock available for this item.</span
                              >
                            </v-tooltip>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <v-tooltip left content-class="custom-left-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="tooltip-border-dashed"
                                  ><b>Committed: </b
                                  >{{
                                    $accountingJS.formatNumber(
                                      data.committed_stock
                                    )
                                  }}</span
                                >
                              </template>
                              <span
                                >Stock that is committed to job(s) but not yet
                                invoiced.</span
                              >
                            </v-tooltip>
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <v-tooltip left content-class="custom-left-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="tooltip-border-dashed"
                                  ><b>Available: </b
                                  >{{
                                    $accountingJS.formatNumber(
                                      data.available_stock
                                    )
                                  }}</span
                                >
                              </template>
                              <span
                                >Available for sale = Stock on Hand - Committed
                                Stock.</span
                              >
                            </v-tooltip>
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatedateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>

                        <!-- custom field column-->
                        <template v-else-if="cols.is_custom_field == 1">
                          <template
                            v-if="
                              checkCustomExistInRow(
                                cols.field,
                                data.custom_fields
                              )
                            "
                          >
                            <template
                              v-for="(row, index) in data.custom_fields"
                            >
                              <div :key="index" v-if="row.label == cols.field">
                                <div v-if="row.value">
                                  <template
                                    v-if="row.field_type == 'date-time'"
                                  >
                                    {{ formatDateTime(row.value) }}
                                  </template>
                                  <template v-else>
                                    {{ row.value }}
                                  </template>
                                </div>
                                <div v-else>
                                  <span>-</span>
                                </div>
                              </div>
                            </template>
                          </template>
                          <template v-else> - </template>
                        </template>
                        <!-- custom field column-->

                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="6" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no competitors assets at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>

        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Competitors Assets</template>
          <template v-slot:body>
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <template v-for="(field, index) in searchableArray">
                  <v-col
                    md="6"
                    :key="index"
                    v-if="field == 'is_low_stock' && false"
                  >
                    <v-checkbox
                      class="mt-0 width-100"
                      color="cyan"
                      v-model.trim="listingSearch.is_low_stock"
                      @change="filterRows"
                    >
                      <template v-slot:label>
                        <label class="custom-form-label m-0 width-100"
                          >Low Stock</label
                        >
                      </template>
                    </v-checkbox>
                  </v-col>

                  <v-col md="6" :key="index" v-else-if="field == 'seller'">
                    <label class="font-weight-700 font-size-16 text-capitalize"
                      >Seller</label
                    >
                    <v-autocomplete
                      hide-details
                      solo
                      flat
                      dense
                      :items="sellers"
                      id="seller12"
                      placeholder="Seller"
                      v-model="listingSearch[field]"
                      class="mt-0"
                      item-text="display_name"
                      item-value="id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Seller(S) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col
                    md="6"
                    :key="index"
                    v-else-if="field == 'power_source'"
                  >
                    <label class="font-weight-700 font-size-16 text-capitalize"
                      >Power Source</label
                    >
                    <v-autocomplete
                      hide-details
                      solo
                      flat
                      dense
                      :items="power_sources"
                      id="dd"
                      placeholder="Power source"
                      v-model="listingSearch[field]"
                      class="mt-0"
                      item-text="text"
                      item-value="text"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Power source(S) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col md="6" :key="index" v-else-if="field == 'factory'">
                    <label class="font-weight-700 font-size-16 text-capitalize"
                      >Factory</label
                    >
                    <v-autocomplete
                      hide-details
                      solo
                      flat
                      dense
                      :items="factories"
                      id="dd"
                      placeholder="Factory"
                      v-model="listingSearch[field]"
                      class="mt-0"
                      item-text="text"
                      item-value="text"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Factory(S) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col md="6" :key="index" v-else-if="field == 'entry_date'">
                    <label class="font-weight-700 font-size-16 text-capitalize"
                      >Entry Date</label
                    >
                    <DatePicker
                      class="pl-2"
                      solo
                      flat
                      :mandatory="false"
                      :nullable="true"
                      placeholder="Entry date"
                      v-model="listingSearch[field]"
                    ></DatePicker>
                  </v-col>

                  <!-- <v-col
                      md="6"
                      :key="index"
                      v-else-if="field == 'country_quote'"
                    >
                      <label
                        class="font-weight-700 font-size-16 text-capitalize"
                        >Country Quote</label
                      >
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="country"
                        id="dd"
                        placeholder="Country Quote"
                        v-model="listingSearch[field]"
                        class="mt-0"
                        item-text="text"
                        item-value="value"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Country Quote(S) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col> -->

                  <v-col
                    md="6"
                    :key="index"
                    v-else-if="
                      field != 'dates' &&
                      field != 'purchase_cost' &&
                      field != 'selling_cost'
                    "
                  >
                    <label
                      class="font-weight-700 font-size-16 text-capitalize"
                      >{{ getFilterLabel(field, "Product") }}</label
                    >
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Competitors Asset')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <!-- price compare dialog-->
        <Dialog :commonDialog="priceCompareDialog" fullscreen>
          <template v-slot:title
            >{{ comapreData.type == "price" ? "Price" : "Specs" }} Compare
            <div style="right: 0; position: absolute">
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-bold-button"
                v-on:click="exportSelectedRowsComapare(comapreData.type)"
                color="cyan white--text"
                depressed
              >
                <v-icon dark left>mdi-database-export</v-icon> Export
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-red-border red white--text"
                v-on:click="priceCompareDialog = false"
              >
                Close
              </v-btn>
            </div>
          </template>
          <template v-slot:body>
            <v-skeleton-loader
              v-if="pageLoading"
              class="my-5"
              type="card"
            ></v-skeleton-loader>

            <!-- New table Fixed-->
            <v-row v-else>
              <v-col md-12>
                <div id="table-scroll" class="table-scroll">
                  <table id="main-table" class="main-table">
                    <thead class="comparision-table-thead">
                      <tr>
                        <th
                          class="td-bg right-border"
                          style="width: 20rem"
                        ></th>
                        <th
                          v-for="(item, index) in comapreData.data.image"
                          :key="index"
                          class="td-bg"
                        >
                          <img style="width: 100px" :src="item" v-if="item" />
                          <!-- https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg -->
                          <img
                            style="width: 100px"
                            :src="$defaultImage"
                            v-else
                          />
                        </th>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">Class</td>
                        <td
                          v-for="(item, index) in comapreData.data.class"
                          :key="index"
                          class="td-bg"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">Brand</td>
                        <td
                          v-for="(item, index) in comapreData.data.brand"
                          :key="index"
                          class="td-bg"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">Series</td>
                        <td
                          v-for="(item, index) in comapreData.data.series"
                          :key="index"
                          class="td-bg"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">
                          Model Code 1
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.model_1"
                          :key="index"
                          class="td-bg"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border bottom-border td-bg">
                          Model Code 2
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.model_2"
                          :key="index"
                          class="td-bg bottom-border"
                        >
                          {{ item }}
                        </td>
                      </tr>
                    </thead>
                    <tbody class="comparision-table-tbody">
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Length to Fork Face (mm) (Fork Face)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .length_to_fork"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Overall Width (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .overall_width"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Turning radius (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .turning_radius"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Ground Clearance [wheelbase center] (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .ground_clearance"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Travel Speed [lad/unlad] (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.travel_speed"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Lift Speed [lad/unlad] (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.lift_speed"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Max gradeability [lad/unlad] (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.max_grade"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>

                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">Model type</td>
                        <td
                          v-for="(item, index) in comapreData.data.model_type"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">Condition</td>
                        <td
                          v-for="(item, index) in comapreData.data.condition"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">Seller</td>
                        <td
                          v-for="(item, index) in comapreData.data.seller"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Mast Height (mm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.mast_height"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">Tires</td>
                        <td
                          v-for="(item, index) in comapreData.data.tires"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">ATT</td>
                        <td
                          v-for="(item, index) in comapreData.data.att"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">Engine</td>
                        <td
                          v-for="(item, index) in comapreData.data.engine"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">
                          Power Source
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.power_source"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">
                          Battery Voltage (V)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .battery_voltage"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">
                          Battery Capacity
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .battery_capacity"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr>
                        <td class="custom-th right-border td-bg">Factory</td>
                        <td
                          v-for="(item, index) in comapreData.data.factory"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>

                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Drive Motor (kW)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.drive_medium"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Hydraulic Motor (kW)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.hydraulic"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Power (kW/rpm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.power"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Torque (Nm/rpm)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.torque"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">
                          Noise level (dB)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data.noise_level"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'specs'">
                        <td class="custom-th right-border td-bg">Entry Date</td>
                        <td
                          v-for="(item, index) in comapreData.data.entry_date"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>

                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Delivery Terms(days)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .delivery_terms"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Warranty (truck)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .warranty_truck"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Warranty (li-ion battery)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .warranty_li_ion_battery"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Country of quote
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .country_quote"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">Quote date</td>
                        <td
                          v-for="(item, index) in comapreData.data.quote_date"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>

                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Local Currency
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .local_currency"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Local Selling Price
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .local_selling_price"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Local Selling Price (FX)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .local_selling_price_fx"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Local Selling Price (JPY)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .local_selling_price_jpy"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Import/DN Currency
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .import_dn_currency"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Import/DN Price
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .import_dn_price"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Import/DN Price (FX)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .import_dn_price_fx"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                      <tr v-if="comapreData?.type == 'price'">
                        <td class="custom-th right-border td-bg">
                          Import/DN Price (JPY)
                        </td>
                        <td
                          v-for="(item, index) in comapreData.data
                            .import_dn_price_jpy"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>

                      <tr
                        v-for="(
                          customF, index
                        ) in comapreData.all_custom_fields"
                        :key="index"
                      >
                        <td class="custom-th right-border td-bg">
                          {{ customF }}
                        </td>
                        <td
                          v-for="(item, index) in comapreData?.data[customF]"
                          :key="index"
                        >
                          {{ item }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:action> </template>
        </Dialog>

        <!-- Delete dialog-->
        <Dialog :commonDialog="deleteDialog">
          <template v-slot:title>Delete Confirmation</template>
          <template v-slot:body>
            <p class="ml-2 red--text font-18" style="font-size: 18px">
              Are you sure want to delete seleted products?
            </p>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="deleteSelectedItems"
            >
              Yes!
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="deleteDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <!-- <template v-if="getPermission('product:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Product</template>
            <template v-slot:body>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 90vh; position: relative"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-row>
                    <template>
                      <a href="./../Sample/product.xlsx" download>
                        <v-btn
                          :disabled="dataLoading"
                          class="mx-2 custom-grey-border custom-bold-button"
                          color="cyan white--text"
                        >
                          Download Sample
                        </v-btn></a
                      >
                    </template>
                  </v-row>
                  <v-row>
                    <template>
                      <div class="container">
                        <form enctype="multipart/form-data" method="POST">
                          <div class="large-4 medium-4 small-4 cell">
                            <label>
                              <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                    </template>
                  </v-row>
                </v-container>
              </perfect-scrollbar>
            </template>
            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template> -->

        <ImportTemplate
          :dialog="importDialog"
          sample-url="public/import-samples/ProductImport.xlsx"
          api-url="productnew/import"
          title="Import Competitors Asset"
          v-on:close="importDialog = false"
          module="product"
        ></ImportTemplate>

        <ImportImgTemplate
          :dialog="importDialogImg"
          sample-url="public/import-samples/ProductImport.xlsx"
          api-url="upload-zip"
          title="Import Images Zip"
          v-on:close="importDialogImg = false"
          module="product"
        ></ImportImgTemplate>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Product Listing-->
</template>

<style>
/* .td-bg{background: #ededed !important;}

tbody tr:nth-child(odd) td:not(.td-bg){
  background: #fff4de !important;
} */

/* th{position: absolute;
  width: 5em;
  left: 0;
  top: auto;} */

/* New ui for alternate */
tbody.comparision-table-tbody tr:nth-child(even) td {
  background: #fff4de !important;
}

thead.comparision-table-thead tr:nth-child(odd) th {
  background: white !important;
}

thead.comparision-table-thead tr:nth-child(even) td {
  background: #fff4de !important;
}

.right-border {
  border-right: 3px solid #bfbfbf;
}
.bottom-border {
  border-bottom: 3px solid #bfbfbf !important;
}
tr td {
  padding: 10px !important;
}
/* End */

*,
*:before,
*:after {
  box-sizing: inherit;
}
.custom-th {
  font-weight: bold;
  border-bottom: 1px solid #bfbfbf;
}

.intro {
  max-width: 1280px;
  margin: 1em auto;
}
.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  height: 600px;
}
.table-scroll table {
  width: 100%;
  min-width: 1280px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}
.table-wrap {
  position: relative;
}
.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  /* border: 0.5px solid #cbc9c9; */
  background: #fff;
  vertical-align: top;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.table-scroll thead {
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
}
.table-scroll thead th {
  color: black;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-width: 15rem;
}
.table-scroll thead tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 5;
  background: #fff;
}
.table-scroll tbody tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 5;
  background: #fff;
}
/* .table-scroll td:second-child {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
} */
/* .table-scroll tbody tr td:first-child {
  position: sticky;
  top: 199px;
} */
/* .table-scroll tbody tr:first-child {
  position: sticky;
  top: 199px;
  z-index: 99;
} */
/* .table-scroll tbody tr:nth-child(4){
  position: sticky;
  top: 309px;
  z-index: 99;
} */
.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #666;
  color: #fff;
  z-index: 4;
}

a:focus {
  background: #d9f2ff;
}

th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
}

thead th:first-child,
tfoot th:first-child {
  z-index: 5;
}
</style>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import ProductMixin from "@/core/lib/product/product.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  DOWNLOAD,
  POST,
  PATCH,
  QUERY,
  UPDATE_ERROR,
  DELETE,
} from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import moment from "moment-timezone";
import JwtService from "@/core/services/jwt.service";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import ImportImgTemplate from "@/view/pages/partials/Import-Img-Template.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { filter, find } from "lodash";
import DatePicker from "@/view/components/DatePicker";

export default {
  mixins: [CommonMixin, ListingMixin, ProductMixin],
  name: "product-list",
  data() {
    return {
      pageLoading: false,
      deleteDialog: false,
      specsOverlay: false,
      priceOverlay: false,
      exportLoading: false,
      searching: false,
      pageModule: "product-listing",
      routeAPI: "product",
      routeName: "product",
      routeDetailName: "product.detail",
      status: "all",
      file: "",
      importDialog: false,
      importDialogImg: false,
      statusList: [],
      customThead: [],
      categoryList: [],
      CategoryCountList: [],
      allowedSearchFields: [
        "barcode",
        "company_name",
        "person_display_name",
        "unit_no",
        "street_1",
        "street_2",
        "zip_code",
        "city_name",
        "state_name",
        "country_name",
      ],
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      moreActions: [
        /*{
          title: "Import Product(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Export Product(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        },
        {
          title: "Delete",
          icon: "mdi-trash-can",
          status: 0,
          action: "delete",
        },
      ],

      power_sources: [],
      sellers: [],
      factories: [],
      country_quote: [],
      country: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
      priceCompareDialog: false,
      price_compare_titles: [
        {
          title: "Brand",
          key: "brand",
        },
        {
          title: "Class",
          key: "class",
        },
        {
          title: "Series",
          key: "series",
        },
        {
          title: "Model Code 1",
          key: "model_code_1",
        },
        {
          title: "Model Code 2",
          key: "model_code_2",
        },
        {
          title: "Model type",
          key: "model_type",
        },
        {
          title: "Condition",
          key: "condition",
        },
        {
          title: "Seller",
          key: "seller",
        },
        {
          title: "Mast Height (mm)",
          key: "mast_height",
        },
        {
          title: "Tyres",
          key: "tyres",
        },
        {
          title: "ATT",
          key: "att",
        },
        {
          title: "Engine",
          key: "engine",
        },
      ],
      price_compare_thead: [
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
        "https://upload.wikimedia.org/wikipedia/commons/0/02/Forklift_Truck-no_lines.svg",
      ],
      price_compare_data: [
        {
          brand: "Toyota",
          class: 1,
          series: 8,
          model_code_1: "8fb30",
          model_code_2: null,
          model_type: "EP4",
          condition: "New",
          seller: "Toyota Tsushuo",
          mast_height: 4700,
          Tyres: "solid",
          att: "ss",
          engine: null,
          power_source: "Li-ion",
          battery_volt: 80,
          battery_capacity: 428,
          factory: "Japan",
          delivery: 180,
          warranty_t: "1yr 2000hrs",
          warranty_b: "5yrs",
        },
        {
          brand: "Toyota",
          class: 1,
          series: 8,
          model_code_1: "8fb30",
          model_code_2: null,
          model_type: "EP4",
          condition: "New",
          seller: "Toyota Tsushuo",
          mast_height: 4700,
          Tyres: "solid",
          att: "ss",
          engine: null,
          power_source: "Li-ion",
          battery_volt: 80,
          battery_capacity: 428,
          factory: "Japan",
          delivery: 180,
          warranty_t: "1yr 2000hrs",
          warranty_b: "5yrs",
        },
        {
          brand: "Toyota",
          class: 1,
          series: 8,
          model_code_1: "8fb30",
          model_code_2: null,
          model_type: "EP4",
          condition: "New",
          seller: "Toyota Tsushuo",
          mast_height: 4700,
          Tyres: "solid",
          att: "ss",
          engine: null,
          power_source: "Li-ion",
          battery_volt: 80,
          battery_capacity: 428,
          factory: "Japan",
          delivery: 180,
          warranty_t: "1yr 2000hrs",
          warranty_b: "5yrs",
        },
        {
          brand: "Toyota",
          class: 1,
          series: 8,
          model_code_1: "8fb30",
          model_code_2: null,
          model_type: "EP4",
          condition: "New",
          seller: "Toyota Tsushuo",
          mast_height: 4700,
          Tyres: "solid",
          att: "ss",
          engine: null,
          power_source: "Li-ion",
          battery_volt: 80,
          battery_capacity: 428,
          factory: "Japan",
          delivery: 180,
          warranty_t: "1yr 2000hrs",
          warranty_b: "5yrs",
        },
      ],
      comapreData: {
        type: "price",
        data: [],
        selectedIds: null,
        all_custom_fields: [],
      },
    };
  },
  components: {
    ImportImgTemplate,
    DatePicker,
    PageHeaderCount,
    ImportTemplate,
    draggable,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    exportSelectedRowsComapare(type = "price") {
      let rows = this.comapreData.selectedRows;
      let rows_type = (this.comapreData.type = type);

      let downloadURL =
        process.env.VUE_APP_API_URL +
        "compare-products/export?filter_ids=" +
        rows +
        "&type=" +
        rows_type;
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("filter_ids", rows);
      //downloadURL.searchParams.append("type", rows_type);

      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }
      });

      //console.log(downloadURL);
      window.open(downloadURL, "_blank");
    },
    checkCustomExistInRow(fieldName = "", customFieldsRow = []) {
      if (customFieldsRow.length <= 0) return false;

      if (find(customFieldsRow, (row) => row.label == fieldName)) {
        return true;
      } else {
        return false;
      }
    },
    exportCompare(type = "price") {
      /* this.$router.push(
        this.getDefaultRoute("compare.competitors", {
          query: {
            type: type,
          },
        })
      ); */
      //selectedRows
      let rowsSelected = this.selectedRows.join(",");
      this.comapreData.selectedRows = rowsSelected;
      this.comapreData.type = type;
      this.priceCompareDialog = true;
      this.pageLoading = true;
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "compare-list/" + type,
          data: { filters_id: rowsSelected },
        })
        .then((response) => {
          this.comapreData.data = response.data?.rows;
          this.comapreData.all_custom_fields = response.data?.all_custom_fields;
          this.pageLoading = false;
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: "goods" },
        })
        .then((response) => {
          console.log(response.data?.supplier);
          _this.power_sources = response.data?.ps_category;
          _this.sellers = response.data?.supplier;
          _this.factories = response.data?.fact_category;
          //_this.country_quote = response.data?.fact_category;
          // _this.power_sources = response.data?.ps_category;

          //_this.factcategoryList = response.data?.fact_category;
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    setCategoryName(name) {
      this.category_name = name;
    },
    getFilteredSupplers(suppliers) {
      const _supplier = filter(suppliers, (row) => row.id);
      return _supplier;
    },
    getTwoCharacter(word) {
      if (word) {
        return word.slice(0, 2).toUpperCase();
      }
      return "";
    },

    formatedateTime(param) {
      return moment(param).format("hh:mm A");
    },
    deleteSelectedItems() {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "delete-products",
          data: {
            products: _this.selectedRows,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.deleteDialog = false;
          _this.dataLoading = false;
        });
    },
    productBulkAction(status, item) {
      if (item.action == "delete") {
        this.deleteDialog = true;
        return false;
      }

      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "product",
          data: {
            status,
            products: _this.selectedRows,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    exportQuotation() {
      let downloadURL = process.env.VUE_APP_API_URL + "products/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkProductExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("product");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "product/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkProductExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "products/export",
        })
        .then(({ data }) => {
          saveAs(data, "products-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Competitors Assets",
      },
    ]);

    if (this.getPermission("product:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items",
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
    ServiceFormEventBus.$on("reload:staffdata", () => {
      this.getRows();
    });

    this.getOptions();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [
    //   {
    //     headerName: "",
    //     field: "id",
    //     sort: null,
    //     visible: true,
    //     fixed: true,
    //     sortable: false,
    //     checkbox: true,
    //     order: 0,
    //     width: "50px",
    //   },
    //   {
    //     headerName: "Image",
    //     field: "image",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Model",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Product's Info",
    //     field: "product_info",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   /* {
    //     headerName: "Commission Rate (%)",
    //     field: "commission_rate",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   }, */
    //   {
    //     headerName: "Price",
    //     field: "price",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   /* {
    //     headerName: "Stock",
    //     field: "quantity",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   }, */
    //   {
    //     headerName: "Created Time",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 8,
    //     width: "170px",
    //   },
    //   {
    //     headerName: "Last Modified Time",
    //     child: "modified_at",
    //     field: "updated_at",
    //     sort: null,
    //     visible: false,
    //     fixed: false,
    //     sortable: false,
    //     order: 9,
    //     width: "170px",
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    // _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
